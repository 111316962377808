import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useProductContext } from '../../products/ProductContext';
import CartItem from './CartItem';
import './Cart.css';

const Cart = () => {
  const { checkout, handleRemoveFromCart, handleUpdateQuantity } = useProductContext();
  const [checkoutCount, setCheckoutCount] = useState(0);
  const [cartOpen, setCartOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [cartHeight, setCartHeight] = useState(window.innerHeight); // State for cart height

  useEffect(() => {
    if (checkoutCount !== 0) {
      setCartOpen(true);
    }
    waitForLineItems();
  }, [checkout]);

  useEffect(() => {
    if (cartOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [cartOpen]);

  const handleCheckout = () => {
    if (checkout) {
      window.location.href = checkout.webUrl;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setCartHeight(window.innerHeight); // Update cart height on resize
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const waitForLineItems = async () => {
    const interval = setInterval(() => {
      if (checkout && checkout.lineItems?.edges) {
        setCheckoutCount(checkout.lineItems.edges.reduce((total, item) => total + item.node.quantity, 0));
        clearInterval(interval);
      }
    }, 100);
  };

  const menuAnimation = {
    initial: windowWidth > 844 ? { translateX: '100%', opacity: 0 } : { translateY: '100%', opacity: 0 },
    animate: cartOpen ? (windowWidth > 844 ? { translateX: '0', opacity: 1 } : { translateY: '0%', opacity: 1 }) : {},
    exit: windowWidth > 844 ? { translateX: '100%', opacity: 0 } : { translateY: '100%', opacity: 0 },
  };

  return (
    <>
      <div className={cartOpen ? "activeBackgroundCart" : ""} onClick={() => setCartOpen(false)} />
      <div className="cart-icon" onClick={() => setCartOpen(!cartOpen)}>
        <img src="/cart.png" alt="Cart" />
        <span className="cart-count">{checkoutCount}</span>
      </div>

      <motion.div
        variants={menuAnimation}
        initial="initial"
        animate={cartOpen ? "animate" : "exit"}
        className="cart-container"
        style={{ height: cartHeight - 50 }} // Adjust the height dynamically
      >
        <div className="cart-header">
          <h4>Din varukorg</h4>
          <img src='/close.png' onClick={() => setCartOpen(false)} alt="Close" className="close-icon" />
        </div>

        {checkout?.lineItems?.edges?.length > 0 ? (
          <>
            <ul className="cart-items">
              <div className="cart-summary">
                {checkout.totalPrice < 250 ? (
                  <div>Du är {250 - checkout.totalPrice} kronor ifrån gratis frakt</div>
                ) : (
                  <div>Gratis frakt</div>
                )}
                <div className="progress-bar">
                  <div className="progress" style={{ width: `${Math.min((checkout.totalPrice / 250) * 100, 100)}%` }} />
                  <div className="progress-labels">
                    <span>0kr</span>
                    <span>250kr</span>
                  </div>
                </div>
              </div>
              {checkout.lineItems.edges.map((item) => (
                <CartItem
                  key={item.node.id}
                  item={item}
                  handleRemoveFromCart={handleRemoveFromCart}
                  handleUpdateQuantity={handleUpdateQuantity}
                />
              ))}

              <div className="cart-footer">
                <div className="cart-summary-item">
                  <span>Sub total</span>
                  <span>{checkout.totalPrice} kr</span>
                </div>
                <div className="cart-summary-item">
                  <span>Beräknad frakt</span>
                  <span>{checkout.totalPrice < 250 ? '79kr' : 'Gratis'}</span>
                </div>
                <div className="cart-summary-total">
                  <span>Totalt</span>
                  <span>{checkout.totalPrice + (checkout.totalPrice < 250 ? 79 : 0)} kr</span>
                </div>
              </div>

            </ul>

            <div className="checkout-button-container">
                <button className="checkout-button" onClick={handleCheckout}>
                  Gå till kassan
                </button>
              </div>
          </>
        ) : (
          <div className="cart-empty">Din varukorg är tom</div>
        )}
      </motion.div>
    </>
  );
};

export default Cart;
