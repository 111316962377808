import React, { createContext, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { getProductDetail, createCheckout, addLineItemsToCheckout, fetchCheckout, removeLineItemsFromCheckout, updateLineItemQuantity } from '../../backend/shopify/shopifyService';

const ProductContext = createContext();

export const useProductContext = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
    // Product and Variant related state
    const [products, setProducts] = useState([]);
    const [variantImages, setVariantImages] = useState({});
    
    // Checkout-related state
    const [checkoutId, setCheckoutId] = useState(null);
    const [checkout, setCheckout] = useState(null);
    const [cart, setCart] = useState({}); // Can be used to store the cart items locally if needed

    // Associate variant images with product variants
    const associateImagesWithVariants = useCallback((images, variants) => {
        const variantImagesMap = {};

        variants.forEach(variant => {
            const variantId = variant.node.id;
            const variantTitle = variant.node.title;

            if (!variantImagesMap[variantId]) {
                variantImagesMap[variantId] = [];
            }

            if (variant.node.image) {
                const variantImage = { node: variant.node.image };
                variantImagesMap[variantId].push(variantImage);

                const img = new Image();
                img.src = variant.node.image.originalSrc;
            }

            const relatedImages = images.filter(image =>
                image.node.altText && image.node.altText.includes(variantTitle)
            );

            relatedImages.forEach(image => {
                const img = new Image();
                img.src = image.node.originalSrc;
            });

            variantImagesMap[variantId] = [...variantImagesMap[variantId], ...relatedImages];
        });

        setVariantImages(prevState => ({
            ...prevState,
            ...variantImagesMap
        }));
    }, []);

    // Fetch product details and associate images with variants
    useEffect(() => {
        const fetchProduct = async () => {
            const productData = await getProductDetail('gid://shopify/Product/9090614067526');
            setProducts(productData);

            const variants = productData.variants.edges;
            const images = productData.images.edges;

            associateImagesWithVariants(images, variants);
        };

        fetchProduct();
    }, [associateImagesWithVariants]);

    useEffect(() => {
        console.log(checkout)
    }, [checkout]);


    //Don't know if this is the optimized solution, will need  to figure it out in the future
    const fetchCheckouts = () => {
        const existingCheckoutId = localStorage.getItem('checkoutId');
        console.log(existingCheckoutId)
    
        if (existingCheckoutId) {
            fetchCheckout(existingCheckoutId)
                .then((existingCheckout) => {
                    // Check if the response has the checkout object
                    if (existingCheckout) {
                        setCheckoutId(existingCheckoutId);
                        setCheckout(existingCheckout); // Make sure 'existingCheckout' contains the correct object
                    } else {
                        console.error('No checkout data found.');
                        setCheckout(null); // Reset checkout if the response is invalid
                    }
                })
                .catch((error) => {
                    console.error('Error fetching checkout:', error);
                    setCheckout(null); // Handle errors gracefully
                });
        }

    }

    // Checkout management
    useEffect(() => {
        fetchCheckouts()
    }, []);

    // Add item to checkout/cart
    const handleAddToCart = async (variantId, quantity = 1) => {
        console.log('handleaddtocart', checkout);
        if (variantId) {
            if (!checkoutId) {
                console.log('if')
                const newCheckout = await createCheckout([{ variantId, quantity }]);
                setCheckoutId(newCheckout.id);
                setCheckout(newCheckout);
                localStorage.setItem('checkoutId', newCheckout.id);
            } else {
                console.log('else')
                const updatedCheckout = await addLineItemsToCheckout(checkoutId, [{ variantId, quantity }]);
                fetchCheckouts()
            }
        }
    };

    useEffect(() => {
        console.log('Updated Checkout:', checkout);
    }, [checkout]);

    const handleUpdateQuantity = async (lineItemId, quantity) => {
        console.log(lineItemId, quantity)
        if (checkoutId && lineItemId) {
            const updatedCheckout = await updateLineItemQuantity(checkoutId, lineItemId, quantity);
            fetchCheckouts()
        }
    };

    const handleRemoveFromCart = async (lineItemId) => {
        if (checkoutId && lineItemId) {
          const updatedCheckout = await removeLineItemsFromCheckout(checkoutId, [lineItemId]);
          fetchCheckouts()
        }
      };


    // Memoize provider values to optimize performance
    const providerValue = useMemo(() => ({
        products,
        variantImages,
        checkoutId,
        checkout,
        handleAddToCart,
        handleRemoveFromCart,
        handleUpdateQuantity // Expose the update quantity function
        
    }), [checkout, products]);

    return (
        <ProductContext.Provider value={providerValue}>
            {children}
        </ProductContext.Provider>
    );
};
